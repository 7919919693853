import { FormControl, InputAdornment, InputLabel, MenuItem, OutlinedInput, Select } from "@mui/material";
import React from "react";
import coin from "../../assets/icons/coin (2).png"
const CurrencyPicker = ({ selectedCurrency, setSelectedCurrency }) => {
  const handleCurrency = (e) => {
    const currency = e.target.value;
    setSelectedCurrency(currency);
  };

  return (
    <div className="lg:min-w-40 max-h-5">
      <FormControl fullWidth>
        <InputLabel
          id="currency-select-label"
          sx={{
            color: "white",
            "&.Mui-focused": {
              color: "red",
            },
            "&.MuiInputLabel-shrink": {
              display: "none",
            },
          }}
        >
          Select Currency
        </InputLabel>
        <Select
          labelId="currency-select-label"
          id="currency-select"
          value={selectedCurrency}
          onChange={handleCurrency}
          input={
            <OutlinedInput
              startAdornment={
                <InputAdornment position="start">
                  <img
                    src={coin}
                    alt="coin"
                    className="mr-3 pb-0.5"
                    />
                </InputAdornment>
              }
              sx={{
                height: "55px",
                width: "190px",
                backgroundColor: "white",
                color: "#1c4546",
                borderRadius: "10px",
                "& .MuiOutlinedInput-notchedOutline": {
                  borderColor: "#1c4546", // Full-time #1c4546 border
                },
                "&:hover .MuiOutlinedInput-notchedOutline": {
                  borderColor: "#1c4546", // Maintain #1c4546 border on hover
                },
                "&.Mui-focused .MuiOutlinedInput-notchedOutline": {
                  borderColor: "#1c4546", // Keep #1c4546 border when focused
                },
                "& .MuiSvgIcon-root": { color: "#1c4546" }
              }}
            />
          }
        >
          <MenuItem value="USD">USD</MenuItem>
          <MenuItem value="INR">INR</MenuItem>
          <MenuItem value="GBP">GBP</MenuItem>
          <MenuItem value="EUR">EUR</MenuItem>
        </Select>
      </FormControl>
    </div>
  );
};

export default CurrencyPicker;
