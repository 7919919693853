import React from 'react';
import { Table, TableBody, TableCell, TableContainer, TableHead, TableRow, Paper } from '@mui/material';
import Close from "../../assets/icons/close.svg";
import Header from "../template/Header";
import Footer from "../template/Footer";
import { useNavigate } from "react-router-dom";
import "react-toastify/dist/ReactToastify.css";
import { useDataContext } from "../Context/context";
import UserController from "../User-Management/user-controller";
import PopUpAlert from "../Pop-Up/PopUp"
import { ToastContainer } from "react-toastify";
import { FaEdit } from "react-icons/fa";
import { MdDelete } from "react-icons/md";

const UserManagement = () => {
  const { setEditData } = useDataContext();
  const navigate = useNavigate();
  const {
    userData,
    handleDelete,
    handleCopy,
    loading,
    filteredData,
    setSearchInput,
    searchInput,
    handleSearch,
    profile
  } = UserController();

  const handleClick = () => {
    setEditData({});
    navigate("/new-user");
  };

  const users = userData?.users?.filter((item) => item.isActive);

  return (
    <>
      <Header />
      <ToastContainer />
      {loading ?
        <div className="loading flex align-center justify-center">
          <div className="loading-Overlay"></div>
        </div>
        :
        <div className="bg-color">
          <div className="body">
            <div className="container-small">
              <h2 style={{ margin: "25px 0px 19px" }}>Your Profile</h2>
              <div></div>
              <div className="bg-white profile-table mb-35">
                <TableContainer component={Paper}>
                  <Table>
                    <TableHead sx={{ backgroundColor: '#afb3af' }}>
                      <TableRow>
                        <TableCell sx={{ width: '200px', textAlign: 'center' }}>Name</TableCell>
                        <TableCell sx={{ width: '200px', textAlign: 'center' }}>Email</TableCell>
                        <TableCell sx={{ width: '250px', textAlign: 'center' }}>Role</TableCell>
                        <TableCell sx={{ width: '200px', textAlign: 'center' }}>Partners</TableCell>
                        <TableCell sx={{ width: '200px', textAlign: 'center' }}>Advertisers</TableCell>
                      </TableRow>
                    </TableHead>
                    <TableBody>
                      {profile?.length &&
                        profile?.map((data, index) => (
                          <TableRow key={index}>
                            <TableCell sx={{ width: '200px', textAlign: 'center' }}>{data.name}</TableCell>
                            <TableCell sx={{ width: '200px', textAlign: 'center' }}>{data.email}</TableCell>
                            <TableCell sx={{ width: '200px', textAlign: 'center' }}>{data.role === "SUPER_ADMIN" ? "SUPER ADMIN" : data?.role}</TableCell>
                            <TableCell sx={{ width: '200px', textAlign: 'center' }}></TableCell>
                            <TableCell sx={{ width: '200px', textAlign: 'center' }}>
                              {data.advertiserIds?.map((e) => e).join(", ")}
                              { }
                            </TableCell>
                          </TableRow>
                        ))}
                    </TableBody>
                  </Table>
                </TableContainer>
              </div>
              {!profile?.length || profile?.[0]?.role === 'ADMIN' || profile?.[0]?.role === 'SUPER_ADMIN' ?
                <>
                  <h2>Users</h2>
                  <p>
                    Review users who have access to your partners and advertisers
                  </p>
                  <div className="flex justify-space items-center">
                    <button className="font-normal px-5 py-2 rounded-[7px] text-white bg-primaryColor border border-primaryColor hover:text-primaryColor hover:bg-white user" onClick={handleClick}>
                      +&nbsp;&nbsp;New User
                    </button>
                    <form className="search-box" style={{ position: 'relative' }}>
                      <input
                        value={searchInput}
                        onChange={(e) => handleSearch(e)}
                        className="search-input"
                        placeholder="Enter a search term or select filters"
                        type="text"
                      />
                      {searchInput && (
                        <img
                          src={Close}
                          className="search-close"
                          alt=""
                          onClick={() => setSearchInput('')}
                          style={{ position: 'absolute', right: '10px', top: '50%', transform: 'translateY(-50%)', cursor: 'pointer' }}
                        />
                      )}
                    </form>
                  </div>
                  <div className="bg-white user-table mt-35">
                    <TableContainer component={Paper}>
                      <Table>
                        <TableHead sx={{ backgroundColor: '#afb3af' }}>
                          <TableRow>
                            <TableCell sx={{ width: '150px', textAlign: 'center' }}>Name</TableCell>
                            <TableCell sx={{ width: '220px', textAlign: 'center' }}>Email</TableCell>
                            <TableCell sx={{ width: '100px', textAlign: 'center' }}>Role</TableCell>
                            <TableCell sx={{ width: '300px', textAlign: 'center' }}>Advertisers</TableCell>
                            <TableCell sx={{ width: '100px', textAlign: 'center' }}>Action</TableCell>
                          </TableRow>
                        </TableHead>
                        <TableBody>
                          {userData?.totalResults >= 1 &&
                            (searchInput === "" ? users : filteredData)?.map(
                              (data, index) => (
                                <TableRow key={index}>
                                  <TableCell sx={{ maxWidth: '150px', textAlign: 'center', whiteSpace: 'normal', wordWrap: 'break-word' }}>{data?.name}</TableCell>
                                  <TableCell sx={{ maxWidth: '220px', textAlign: 'center', whiteSpace: 'normal', wordWrap: 'break-word' }}>{data?.email}</TableCell>
                                  <TableCell sx={{ maxWidth: '100px', textAlign: 'center', whiteSpace: 'normal', wordWrap: 'break-word' }}>{data?.role === "SUPER_ADMIN" ? "SUPER ADMIN" : data?.role}</TableCell>
                                  <TableCell sx={{ maxWidth: '300px', textAlign: 'center', overflow: 'auto' }}>
                                    {data?.advertiserIds?.map((e) => e).join(", ")}
                                  </TableCell>
                                  <TableCell sx={{ textAlign: 'center' }}>
                                    <div className='flex justify-center gap-5 width: 100px'>
                                      <FaEdit
                                        className={'pointer-cell'}
                                        size={20}
                                        color='#4d4b4b'
                                        onClick={() => handleCopy(data?.id)}
                                      />
                                      <MdDelete
                                        className={'pointer-cell'}
                                        size={20}
                                        color='#4d4b4b'
                                        onClick={() => {
                                          PopUpAlert({ data: data, value: 'user', handleAction: handleDelete })
                                        }} />
                                    </div>
                                  </TableCell>
                                </TableRow>
                              ))}
                        </TableBody>
                      </Table>
                    </TableContainer>
                    {
                      ((filteredData?.length === 0 && searchInput !== "") || users?.length === 0) &&
                      <>
                        <div className="container1">
                          {users?.length ?
                            <p className="centered-text">Sorry no user matches</p> :
                            <p className="centered-text">No user added by you till now.</p>}
                        </div>
                      </>
                    }
                  </div>
                </> :
                <div className="bg-white user-table mt-35">
                  <div className="container1">
                    <p className="centered-text">You dont have permission for user management</p>
                  </div>
                </div>}
            </div>
          </div>
        </div>
      }

      <Footer />
    </>
  );
};

export default UserManagement;
