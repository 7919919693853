import axios from 'axios';
import useToast from "../Hooks/Toast";
import { cookieGetter } from '../utils/HelperFunction'
import { useNavigate } from 'react-router-dom';
import { NEW_BACKENDBASEURL } from '../utils/constants';


const useBudgetAndExpense = (handleClose) => {
    const appIdToken = cookieGetter("appIdToken");
    const navigate = useNavigate();
    const notify = useToast();


    const apiCall = async (bodyData,budgetId) => {
        const url = `${NEW_BACKENDBASEURL}/budget/add_budget_or_expense/${budgetId}`;
        const method = 'POST'

        try {
            const response = await axios({
                method: method,
                url: url,
                headers: {
                    "Content-Type": "application/json",
                    Authorization: `Bearer ${appIdToken}`,
                },
                data : bodyData,
            });

            if (response.status !== 200) {
                notify(`There is some issue in adding ${bodyData?.type}please try again`, 'error');
            } else {
                notify(`${bodyData?.type} is added sucessfully`, 'success');
                handleClose();
                navigate("/budget");
            }
        } catch (error) {
            notify(`There is some issue in adding ${bodyData?.type} please try again`, 'error');
            console.error('Error:', error);
        }
    }
    return { apiCall };
};

export default useBudgetAndExpense;
