import { useState, useEffect } from "react";
import { formatCurrency } from "../utils/currencyFormatter";

const currencyRates = {
  INR: 1,
  USD: 0.012,
  EUR: 0.011,
  GBP: 0.0097,
};

const UseCurrencyConverter = (apiAmount) => {
  const [selectedCurrency, setSelectedCurrency] = useState("INR");
  const [convertedAmount, setConvertedAmount] = useState(apiAmount); // Store the converted amount

  // Function to convert the amount based on selected currency
  const convertAmount = (amount, currency) => {
    const rate = currencyRates[currency];
    return formatCurrency((amount * rate).toFixed(2)); // Adjust for decimal places
  };

  // Effect to trigger conversion when the selectedCurrency changes
  useEffect(() => {
    if (apiAmount) {
      const newAmount = convertAmount(apiAmount, selectedCurrency);
      setConvertedAmount(newAmount); // Update the converted amount
    }
  }, [selectedCurrency, apiAmount]);

  return {
    selectedCurrency,
    setSelectedCurrency,
    convertAmount,
    convertedAmount,
  };
};

export default UseCurrencyConverter;
