import React, { useEffect, useState } from 'react';
import { Select, MenuItem, Checkbox, ListItemText, InputAdornment, OutlinedInput, CircularProgress, Button } from '@mui/material';
import CampaignDetails from '../Hooks/Dashboard/useCampaign';
import { formatValue } from '../utils/currencyFormatter';
import campaignIcon from '../../assets/icons/analytics (2) 2.png';
import UseCurrencyConverter from '../Hooks/useCurrencyConverter';

const CampaignTable = ({ selectedDateRange, advertiserId, selectedCurrency }) => {
    const { campaignData, loading, campaignApiCall } = CampaignDetails();
    const { convertAmount } = UseCurrencyConverter();
    const defaultSelectedMetrics = ["impressions", "clicks", "total_conversions", "revenue"];
    const [selectedMetrics, setSelectedMetrics] = useState(defaultSelectedMetrics);
    const [currentPage, setCurrentPage] = useState(1);
    const rowsPerPage = 4;

    const availableMetrics = [
        "impressions", "clicks", "total_conversions", "revenue", "conversion_rate", "ctr", "cpm", "cpa", "cpc"
    ];

    const displayMetrics = {
        impressions: "Impressions",
        clicks: "Clicks",
        ctr: "CTR",
        revenue: "Cost",
        conversion_rate: "Conversion Rate",
        total_conversions: "Conversions",
        cpm: "CPM",
        cpa: "CPA",
        cpc: "CPC"
    };

    const campaignObject = {
        startDate: selectedDateRange?.startDate,
        endDate: selectedDateRange?.endDate,
        advertiserId: advertiserId,
    };

    useEffect(() => {
        if (advertiserId) {
            campaignApiCall(campaignObject);
        }
    }, [advertiserId, selectedDateRange]);

    const handleMetricChange = (event) => {
        const {
            target: { value },
        } = event;

        if (value.length < 3) {
            return;
        }

        if (value.length > 4) {
            const newSelectedMetrics = value.slice(-4);
            setSelectedMetrics(newSelectedMetrics);
        } else {
            setSelectedMetrics(value);
        }
    };

    // Calculate the current rows for the current page
    const indexOfLastRow = currentPage * rowsPerPage;
    const indexOfFirstRow = indexOfLastRow - rowsPerPage;
    const currentRows = campaignData?.slice(indexOfFirstRow, indexOfLastRow);

    // Pagination controls
    const totalPages = Math.ceil(campaignData?.length / rowsPerPage);

    const handleNextPage = () => {
        if (currentPage < totalPages) {
            setCurrentPage(currentPage + 1);
        }
    };

    const handlePrevPage = () => {
        if (currentPage > 1) {
            setCurrentPage(currentPage - 1);
        }
    };

    const handlePageClick = (pageNumber) => {
        setCurrentPage(pageNumber);
    };

    return (
        <div className='mb-20'>
            <div className="flex justify-between items-center mb-4">
                <h2 className="font-medium text-xl">Campaign Table</h2>
                <Select
                    multiple
                    value={selectedMetrics}
                    onChange={handleMetricChange}
                    renderValue={() => "Select Metrics"}
                    displayEmpty
                    style={{ width: 200 }}
                    input={
                        <OutlinedInput
                            startAdornment={
                                <InputAdornment position="start">
                                    <img
                                        src={campaignIcon}
                                        alt="marketing"
                                        className="mr-6 pb-0.5"
                                    />
                                </InputAdornment>
                            }
                            sx={{
                                height: "55px",
                                minWidth: "220px",
                                backgroundColor: "white",
                                color: "#1c4546",
                                borderRadius: "10px",
                                "& .MuiOutlinedInput-notchedOutline": {
                                    borderColor: "#1c4546",
                                },
                                "&:hover .MuiOutlinedInput-notchedOutline": {
                                    borderColor: "#1c4546",
                                },
                                "&.Mui-focused .MuiOutlinedInput-notchedOutline": {
                                    borderColor: "#1c4546",
                                },
                                "& .MuiSvgIcon-root": { color: "#1c4546" }
                            }}
                        />
                    }
                >
                    {availableMetrics?.map((metric) => (
                        <MenuItem key={metric} value={metric}>
                            <Checkbox checked={selectedMetrics?.indexOf(metric) > -1}
                                sx={{
                                    color: "#1c4546",
                                    '&.Mui-checked': {
                                        color: "#1c4546",
                                    },
                                    '&:hover': {
                                        backgroundColor: 'rgba(28, 69, 70, 0.08)',
                                    },
                                }}
                            />
                            <ListItemText primary={displayMetrics[metric]} />
                        </MenuItem>
                    ))}
                </Select>
            </div>
            <div className='rounded-xl border-collapse overflow-hidden border border-[#1c4546]'>
                <table className="min-w-full overflow-hidden">
                    <thead className='bg-[#E6F9FF]'>
                        <tr>
                            <th className="p-2 text-base font-medium" style={{ width: '200px', height: '50px' }}>Campaign Name</th>
                            {selectedMetrics?.map((metric) => (
                                <th key={metric} className="border-l border-[#1c4546] p-2 text-base font-medium" style={{ width: '150px', height: '50px' }}>
                                    {displayMetrics[metric]}
                                </th>
                            ))}
                        </tr>
                    </thead>
                    <tbody>
                        {(!advertiserId || loading) ? (
                            <tr>
                                <td colSpan={1 + selectedMetrics?.length} className="border-t border-[#1c4546] text-center p-4">
                                    <CircularProgress
                                        size={40}
                                        color="inherit"
                                        sx={{ color: "#1c4546", marginLeft: '78px' }}
                                    />
                                </td>
                            </tr>
                        ) : (
                            currentRows?.map(([campaignName, metrics]) => (
                                <tr key={campaignName} style={{ height: '50px' }}>
                                    <td className="border-r border-t border-[#1c4546] p-2 text-sm text-center" style={{ width: '200px' }}>{campaignName}</td>
                                    {selectedMetrics?.map((metric) => {
                                        const metricData = metrics?.find(m => m?.name === metric);
                                        return (
                                            <td key={metric} className="border-l border-t border-[#1c4546] p-2 text-sm text-center" style={{ width: '150px' }}>
                                                {formatValue(metric, (metricData ? metricData?.value : 0), selectedCurrency, convertAmount)}
                                            </td>
                                        );
                                    })}
                                </tr>
                            ))
                        )}
                    </tbody>
                </table>
            </div>
            <div className={` ${totalPages <= 1 ? "hidden" : "flex justify-center gap-10 items-center mt-4"}`}>
                <Button
                    onClick={handlePrevPage}
                    disabled={currentPage === 1}
                    variant='outlined'
                    sx={{
                        color: "#1c4546", borderColor: "#1c4546", backgroundColor: "white", margin: '0 2px'
                    }}
                >
                    Previous
                </Button>
                <div>
                    {Array.from({ length: totalPages }, (_, index) => (
                        <Button
                            key={index}
                            onClick={() => handlePageClick(index + 1)}
                            sx={{
                                color: currentPage === index + 1 ? "white" : "#1c4546",
                                backgroundColor: currentPage === index + 1 ? "#1c4546" : "white",
                                borderColor: "#1c4546",
                                margin: '0 2px',
                                '&:hover': {
                                    backgroundColor: "#1c4546", // Maintain white background on hover
                                    color: "white"
                                }
                            }}
                            variant='outlined'
                        >
                            {index + 1}
                        </Button>
                    ))}
                </div>
                <Button
                    onClick={handleNextPage}
                    disabled={currentPage === totalPages}
                    variant='outlined'
                    sx={{
                        color: "#1c4546", borderColor: "#1c4546", backgroundColor: "white", margin: '0 2px'
                    }}
                >
                    Next
                </Button>
            </div>
        </div>
    );
}

export default CampaignTable;