import { useState } from "react";
import { useNavigate } from "react-router-dom";
import { cookieSetter } from '../utils/HelperFunction';
import { useDataContext } from "../Context/context"
import useToast from "../Hooks/Toast"
import { getEmailFromToken } from '../utils/getUserDetail';
import { NEW_BACKENDBASEURL } from "../utils/constants";

const LoginController = () => {
  const { toSetLogin } = useDataContext();
  const notify = useToast();
  const [googleLoginInfo, setGoogleLoginInfo] = useState({
    inProgress: false,
    error: "",
  });

  const [loading, setLoading] = useState(false);
  const navigate = useNavigate();

  const responseGoogle = async ({ credential, profileObj }) => {
    try {
      setLoading(true);
      // Set as logging in
      setGoogleLoginInfo({ ...googleLoginInfo, inProgress: true });

      const response = await fetch(`${NEW_BACKENDBASEURL}/users/google-login`, {
        method: "POST",
        headers: { Authorization: `Bearer ${credential}` },
      });
      if (response.ok) {
        notify(`Welcome ! Youre logedIn`, 'success');
        const { jwtToken } = await response.json();
        onAppSignIn(jwtToken);
      } else {
        notify(`There is some issue please try again`, 'error');
        const { data } = await response.json();
        setGoogleLoginInfo({
          ...googleLoginInfo,
          error: data?.detail || "Something went wrong... Please try again!",
        });
      }
    } catch (error) {
      console.error("Error:", error);
    } finally {
      setLoading(false);
      setGoogleLoginInfo({ ...googleLoginInfo, inProgress: false });
    }
  };

  const handleEmailLogin = async (email, password) => {
    try {
      setLoading(true);
      const response = await fetch(`${NEW_BACKENDBASEURL}/users/email-login`, {
        method: "POST",
        headers: { "Content-Type": "application/json" },
        body: JSON.stringify({ email, password }),
      });

      if (response.ok) {
        notify(`Welcome! You're logged in`, "success");
        const data = await response.json();
        onAppSignIn(data.jwtToken);
      } else {
        const errorData = await response.json();
        notify(errorData?.detail || "Invalid credentials", "error");
      }
    } catch (error) {
      console.error("Error:", error);
      notify("Something went wrong. Please try again.", "error");
    } finally {
      setLoading(false);
    }
  };

  const handlePasswordReq = async (email, callback) => {
    try {
      setLoading(true);
      const response = await fetch(`${NEW_BACKENDBASEURL}/users/forgot-password`, {
        method: 'POST',
        headers: { "Content-Type": "application/json" },
        body: JSON.stringify({ email }),
      });
      if (response.ok) {
        const data = await response.json();
        notify(`${data?.message || 'Reset Link has been sent on your mail!'}`, 'success');
        callback(data?.message || 'Reset Link has been sent to your email!');
      }
      else {
        const errorData = await response.json();
        notify(`${errorData?.detail}, Please try again!` || "Invalid credentials, Please try again!", "error");
        callback(`${errorData?.detail}, Please try again!` || "Invalid credentials, Please try again!");
        setTimeout(() => {
          window.location.reload();
        }, 5000);
      }
    }
    catch (error) {
      console.error("Error:", error);
      notify("Something went wrong. Please try again.", "error");
      callback("Something went wrong. Please try again.");
      setTimeout(() => {
        window.location.reload();
      }, 5000);
    } finally {
      setLoading(false);
    }
  }

  const onAppSignIn = async (idToken) => {
    localStorage.clear();
    // Cookies.set("appIdToken", idToken);
    // Cookies.set("ExpiryTime",moment())
    await cookieSetter("appIdToken", idToken)
    getEmailFromToken(idToken);
    setLoading(false);
    toSetLogin();
    navigate("/home", { replace: true });
    notify();
  };

  return {
    loading,
    responseGoogle,
    handleEmailLogin,
    handlePasswordReq,
  };
};

export default LoginController;
// {"detail":"Invalid email or password."}