import React, { useEffect } from 'react';
import { PieChart, Pie, Cell, ResponsiveContainer, Tooltip } from 'recharts';
import UsePieChartData from '../Hooks/Dashboard/usePieChartData';
import { CircularProgress } from '@mui/material';
import { formatValue } from '../utils/currencyFormatter';
import UseCurrencyConverter from '../Hooks/useCurrencyConverter';

const DEVICE_COLORS = {
    "Smart Phone": '#FFEBB2',
    "Desktop": '#FFCDF7',
    "Others": '#A7FFBC',
    "Connected TV": '#FFC7D2'
};

// Customized label rendering function
const renderCustomizedLabel = ({ cx, cy, midAngle, innerRadius, outerRadius, percent }) => {
    const radius = innerRadius + (outerRadius - innerRadius) * 0.75;
    const x = cx + radius * Math.cos(-midAngle * (Math.PI / 180));
    const y = cy + radius * Math.sin(-midAngle * (Math.PI / 180));

    return percent > 0 ? (
        <text x={x} y={y} fill="#1C4546" fontWeight={500} textAnchor="middle" dominantBaseline="central">
            {`${(percent * 100).toFixed(0) > 0 ? `${(percent * 100).toFixed(0)}%` : ''} `}
        </text>
    ) : null;
};

const PieChartDashboard = ({ selectedDateRange, advertiserId, selectedCurrency }) => {
    const staticLabels = ['Impression', 'Click', 'Cost', 'Conversion'];
    const metrics = ['impressions', 'clicks', 'revenue', 'total_conversions'];
    const { pieChartApiCall, loading, pieChartData } = UsePieChartData();
    const { convertAmount } = UseCurrencyConverter();

    const pieChartObject = {
        startDate: selectedDateRange?.startDate,
        endDate: selectedDateRange?.endDate,
        advertiserId: advertiserId,
    };

    useEffect(() => {
        if (advertiserId && selectedDateRange?.startDate && selectedDateRange?.endDate) {
            pieChartApiCall(pieChartObject);
        }
    }, [advertiserId, selectedDateRange]);

    const data = pieChartData || {};
    if (!pieChartData) {
        return (
            <div>
                <div className="font-medium text-xl pb-10 2xl:pl-8">Detailed information as pie chart</div>
                <div className="flex flex-col gap-3 justify-center items-center">
                    <CircularProgress size={40} color="inherit" sx={{ color: "#1c4546" }} />
                    <div>Loading...</div>
                </div>
            </div>
        );
    }
    return (
        <div>
            <div className="font-medium text-xl pb-10 2xl:pl-8">Detailed information as pie chart</div>
            <div className="flex flex-wrap justify-around w-full gap-5">
                {metrics?.map((metric, index) => {
                    // Calculate total value for the current metric across all devices
                    const total = Object.entries(data).reduce((sum, [, values]) => {
                        return sum + (values.find(v => v.name === metric)?.value || 0);
                    }, 0);

                    return (
                        <div key={index} className="text-center mb-4 relative">
                            {/* Pie chart container with border */}
                            <div className="border-[2px] border-light-gray rounded-lg shadow-lg p-8 relative">
                                {/* Top label with a red background overlapping the top border */}
                                <div className="absolute w-[150px] -top-3 left-1/2 transform -translate-x-1/2 text-sm bg-[#E6F9FF] border-[#1C4645] border rounded-[5px] text-[#00292C] px-2 py-0.5">
                                    {staticLabels[index]}
                                </div>
                                <ResponsiveContainer width={205} height={205}>
                                    <PieChart>
                                        <Pie
                                            data={Object.entries(data)?.map(([key, values]) => ({
                                                name: key,
                                                value: values.find(v => v?.name === metric)?.value || 0,
                                                percent: total > 0 ? ((values.find(v => v?.name === metric)?.value || 0) / total) : 0
                                            }))}
                                            cx="50%"
                                            cy="50%"
                                            labelLine={false}
                                            label={renderCustomizedLabel}
                                            outerRadius={100}
                                            dataKey="value"
                                            paddingAngle={1.5}
                                        >
                                            {Object.keys(data)?.map((key, cellIndex) => (
                                                <Cell
                                                    key={`cell-${cellIndex}`}
                                                    fill={DEVICE_COLORS[key] || '#8884d8'}
                                                    strokeWidth={1} // Thickness of the border
                                                />
                                            ))}
                                        </Pie>
                                        <Tooltip
                                            formatter={(value, name) => {
                                                const metricKey = metrics[index];

                                                const deviceName = name === "Smart Phone" ? "Mobile" :
                                                    name === "Desktop" ? "Desktop" :
                                                        name === "Connected TV" ? "Connected TV" : "Other";

                                                const formattedValue = formatValue(metricKey, value, selectedCurrency, convertAmount);

                                                return [`${deviceName}: ${formattedValue}`];
                                            }}
                                            contentStyle={{
                                                backgroundColor: '#fff',
                                                borderRadius: '5px',
                                                padding: '10px',
                                                boxShadow: '0px 0px 5px rgba(0, 0, 0, 0.2)',
                                            }}
                                            itemStyle={{
                                                color: '#1C4645',
                                                fontSize: '14px',
                                            }}
                                            labelFormatter={(label) => {
                                                if (label === "Smart Phone") return "Mobile";
                                                if (label === "Desktop") return "Desktop";
                                                if (label === "Connected TV") return "Connected TV";
                                                return "Other";
                                            }}
                                        />
                                    </PieChart>
                                </ResponsiveContainer>
                            </div>
                        </div>
                    );
                })}
            </div>

            <div className='flex gap-12 mt-7 max-sm:flex-col max-sm:gap-5 justify-center items-center'>
                <div className="flex gap-2 justify-center items-center">
                    <div className="bg-[#FFEBB2] h-6 w-6 border border-gray-500 rounded-sm"></div>Mobile
                </div>
                <div className="flex gap-2 justify-center items-center">
                    <div className="bg-[#FFCDF7] h-6 w-6 border border-gray-500 rounded-sm"></div>Desktop
                </div>
                <div className="flex gap-2 justify-center items-center">
                    <div className="bg-[#FFC7D2] h-6 w-6 border border-gray-500 rounded-sm"></div>Connected TV
                </div>
                <div className="flex gap-2 justify-center items-center">
                    <div className="bg-[#A7FFBC] h-6 w-6 border border-gray-500 rounded-sm"></div>Other
                </div>
            </div>
        </div>
    );
};

export default PieChartDashboard;