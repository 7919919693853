import axios from "axios";
import useToast from "../Hooks/Toast";
import { cookieGetter } from "../utils/HelperFunction";
import { useNavigate } from "react-router-dom";
import { NEW_BACKENDBASEURL } from "../utils/constants";

const useResetPassword = () => {
  const appIdToken = cookieGetter("appIdToken");
  const navigate = useNavigate();
  const notify = useToast();

  const apiCall = async (bodyData) => {
    const url = `${NEW_BACKENDBASEURL}/users/reset-password`;
    const method = "POST";

    try {
      const response = await axios({
        method: method,
        url: url,
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${appIdToken}`,
        },
        data: bodyData,
      });

      if (response.status !== 200) {
        notify(
          `There is some issue in creating new password please try again`,
          "error"
        );
      } else {
        if (response?.data?.message) {
          notify(response?.data?.message, "success");
        } else if (response?.data?.detail) {
          notify(response?.data?.detail, "warning");
        } else if (response?.message) {
          notify(response?.message);
        }

        setTimeout(() => {
          navigate("/login");
        }, 5000);
      }
    } catch (error) {
      notify(
        `There is some issue in creating account please try again`,
        "error"
      );
      console.error("Error:", error);
    }
  };
  return { apiCall };
};

export default useResetPassword;
