import Cookies from "js-cookie";
import moment from "moment";

export async function cookieSetter(key, value) {
  const expiryTime = moment().add(24, "hours").toISOString();
  Cookies.set(key, value);
  Cookies.set("ExpiryTime", expiryTime);
}

export function cookieGetter(key) {
  const cookie = Cookies.get(key);
  const expiryTime = Cookies.get("ExpiryTime");

  if (!expiryTime || moment().isAfter(moment(expiryTime))) {
    Cookies.remove(key);
    Cookies.remove("ExpiryTime");
    return null;
  }
  return cookie;
}

export function findInvalidEmail(emails) {
  const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;

  for (const email of emails) {
    if (!emailRegex.test(email)) {
      return {
        isValid: false,
        email: email,
      };
    }
  }

  return {
    isValid: true,
    email: null,
  };
}

export function calculatePercentageChange(
  selectedDateValue,
  comparisonDateValue
) {
  // Ensure both values are numbers
  const selectedValue = parseFloat(selectedDateValue);
  const comparisonValue = parseFloat(comparisonDateValue);

  // Check if the comparison value is zero to avoid division by zero
  if (comparisonValue === 0) {
    if (selectedValue === 0) {
      return 0; // No change if both are 0
    }
    return Infinity; // Infinity if the comparison value is 0 and selected value is not 0
  }

  // Calculate percentage change
  const percentageChange =
    ((selectedValue - comparisonValue) / comparisonValue) * 100;

  return parseFloat(percentageChange.toFixed(2)); // Return the result rounded to 2 decimal places
}
