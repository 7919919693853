import { useState } from "react";
import { cookieGetter } from "../../utils/HelperFunction";
import axios from "axios";
import { NEW_BACKENDBASEURL } from "../../utils/constants";

const CountryDetails = () => {
    const [country, setCountry] = useState([]);
    const [loading, setLoading] = useState(false);
    const getCountry = async (advertiserId) => {
        const appIdToken = cookieGetter("appIdToken");
        setLoading(true);
        try {
            const response = await axios.get(
                `${NEW_BACKENDBASEURL}/dv360/countries?advertiser_id=${advertiserId}`,
                {
                    headers: {
                        Authorization: `Bearer ${appIdToken}`,
                        "Content-Type": "application/json",
                    },
                }
            );

            setCountry(response?.data || []);
            setLoading(false);

        } catch (error) {
            console.error("Error fetching ledger details:", error);
        }
    };

    return {
        country,
        loading,
        setCountry,
        getCountry,
    };
};
export default CountryDetails;
