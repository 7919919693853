import React, { useEffect, useState } from "react";
import chatbotIcon from "../../assets/icons/chatbot.png";

const Footer = () => {
    const [currentPath, setCurrentPath] = useState("");

    useEffect(() => {
        setCurrentPath(window.location.pathname);

        const handleLocationChange = () => {
            setCurrentPath(window.location.pathname);
        };

        window.addEventListener("popstate", handleLocationChange);

        return () => {
            window.removeEventListener("popstate", handleLocationChange);
        };
    }, []);

    const isChatBotPage = currentPath === "/chatBot";

    return (
        <>
            {!isChatBotPage && (
                <div>
                    <a
                        href="/chatBot"
                        className="bg-white flex justify-center hover:bg-gray-200 items-center border border-gray-900 p-4 px-4 py-3 rounded-full text-gray-800 font-medium fixed bottom-10 right-5"
                    >
                        <img src={chatbotIcon} alt="chatbotIcon" className="h-14 w-14" />
                    </a>
                </div>
            )}

            <div className="footer">
                <p className="flex text-xs 2xl:text-base justify-center align-center m-0">
                    <span>&copy;</span>&nbsp;&nbsp;A product by MarketingLiv
                </p>
            </div>
        </>
    );
};

export default Footer;