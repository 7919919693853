import React, { useState } from "react";
import "../../assets/style/common.scss";
import LoginIcon from "../../assets/icons/google.svg";
import ShowPassword from "../../assets/icons/showPassword.svg";
import HidePassword from "../../assets/icons/hidePassword.svg";
import { GoogleOAuthProvider } from "@react-oauth/google";
import { GoogleLogin } from "@react-oauth/google";
import LoginController from "./login-controller";
import { ToastContainer } from "react-toastify";
import inner from '../../assets/images/Inner Circle.png'
import idea from '../../assets/icons/idea (5).png'
import privacy from '../../assets/icons/privacy (2) (1).png'
import diagram from '../../assets/icons/diagram (4).png'
import goal from '../../assets/icons/goal (2).png'
import outer from '../../assets/images/Outer Circle.png'
import dv360 from '../../assets/images/dv360_logo.png'
import success from '../../assets/icons/success.png'
import notSuccess from '../../assets/icons/not_success.png'

const Login = () => {
  const [values, setValues] = React.useState({
    password: "",
    showPassword: false,
  });
  const { responseGoogle, loading, handleEmailLogin, handlePasswordReq } = LoginController();

  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [errors, setErrors] = useState({});
  const [forgotPassword, setForgotPassword] = useState(false);
  const [resetMessage, setResetMessage] = useState("");

  const handleClickShowPassword = () => {
    setValues({ ...values, showPassword: !values.showPassword });
  };

  const handleMouseDownPassword = (event) => {
    event.preventDefault();
  };

  const handlePasswordChange = (prop) => (event) => {
    setValues({ ...values, [prop]: event.target.value });
  };

  const validatePassword = (password) => {
    const passwordRegex = /^(?=.*[0-9])(?=.*[!@#$%^&*])[a-zA-Z0-9!@#$%^&*]{6,128}$/;
    return passwordRegex.test(password) && !password.includes(" ");
  };

  const handleSubmit = () => {

    let formErrors = {};

    if (!email) {
      formErrors.email = "Email is required";
    }

    if (!password) {
      formErrors.password = "Password is required";
    } else if (!validatePassword(password)) {
      formErrors.password = [
        "Password must:",
        "• Be between 6 and 128 characters long",
        "• Contain at least one number",
        "• Contain at least one special character (!@#$%^&*)",
        "• Not contain spaces",
      ];
    }

    setErrors(formErrors);

    if (Object.keys(formErrors).length === 0) {
      handleEmailLogin(email, password);
    }
  };

  const handlePasswordReset = (email) => {
    handlePasswordReq(email, (message) => {
      setResetMessage(message);
    });
  }

  return (
    <>
      {loading ? (
        <div className="none">
          <div className="loading flex align-center justify-center">
            <div className="loading-Overlay"> </div>
          </div>
        </div>
      ) : (
        <div className="container-login">
          <div className="login">
            <div className="login-box max-xl:w-[200px]">


              {forgotPassword ? (
                <>
                  <h1 className="text-2xl mb-6">Forgot Password</h1>
                  {resetMessage ? (
                    <div className="flex justify-center items-center gap-2">

                      {["A password reset link was already sent. Please check your email.", "Password reset link sent!"].includes(resetMessage) ?
                        <img src={success} alt="success" className="w-7" />
                        :
                        <img src={notSuccess} alt="not success" className="w-7" />
                      }

                      <p className="text-sm mt-4 text-gray-600">{resetMessage}</p>
                    </div>
                  )
                    :
                    <div>
                      <input
                        type="email"
                        required
                        placeholder="Enter your email"
                        className="input-1"
                        value={email}
                        onChange={(e) => setEmail(e.target.value)}
                      />
                      {errors.email && (
                        <span className="text-red-500 text-xs pt-1">{errors.email}</span>
                      )}
                      <button
                        onClick={() => handlePasswordReset(email)}
                        className="button bg-primaryColor mt-5 py-3 px-6 rounded-full shadow-lg hover:bg-purple-600 transition"
                      >
                        Send Reset Link
                      </button>
                    </div>
                  }
                  <div className="flex justify-center items-center">
                    <button
                      onClick={() => setForgotPassword(false)}
                      className="mt-4 text-sm text-gray-500 hover:text-gray-700"
                    >
                      ⬅️ Back to Login
                    </button>
                  </div>

                </>
              ) : (
                <>
                  <h1>Sign In</h1>
                  <input
                    type="text"
                    required
                    placeholder="Email"
                    className="input-1"
                    value={email}
                    onChange={(e) => setEmail(e.target.value)}
                  />
                  {errors.email && <span className="text-red-500 text-xs pt-1">{errors.email}</span>}
                  <div className="password mt-7">
                    <input
                      className="input-1"
                      required
                      type={values.showPassword ? "text" : "password"}
                      placeholder="Password"
                      value={password}
                      onChange={(e) => setPassword(e.target.value)}
                    />
                    <span
                      onClick={handleClickShowPassword}
                      onMouseDown={handleMouseDownPassword}
                    >
                      {values.showPassword ? (
                        <img src={ShowPassword} alt="Log in" />
                      ) : (
                        <img src={HidePassword} alt="Log in" />
                      )}
                    </span>
                  </div>
                  {errors?.password &&
                    <div className="text-red-500 mt-1">
                      {errors?.password?.map((error, index) => (
                        <span key={index} className="block text-xs xl:text-sm">{error}</span>
                      ))}
                    </div>
                  }

                  <button onClick={handleSubmit} className="button bg-primaryColor mt-[35px] py-5">Log in</button>
                  <button
                    onClick={() => setForgotPassword(true)}
                    className="my-4 mx-auto w-full hover:underline">
                    Forgot Password?
                  </button>
                  <GoogleOAuthProvider clientId={process.env.REACT_APP_GOOGLE_API_TOKEN}>
                    <GoogleLogin
                      render={(renderProps) => (
                        <button
                          className="log-in-btn button flex justify-center rounded-[10px]"
                          onClick={renderProps.onClick}
                          disabled={renderProps.disabled}
                        >
                          <img src={LoginIcon} alt="Log in" />
                          Log In with Google
                        </button>
                      )}
                      onSuccess={responseGoogle}
                      onFailure={responseGoogle}
                      cookiePolicy="single_host_origin"
                    />
                  </GoogleOAuthProvider>
                </>
              )}
              <ToastContainer />
            </div>
          </div>

          <div className="outer-ring absolute right-[10%] 2xl:right-[9%] top-[5%] lg:top-[13%] 2xl:top-[17%] max-2xl:w-[35%]">
            <div className="inner-ring relative overflow-hidden">
              <img
                src={dv360}
                alt="dv360"
                className="absolute top-[40%] left-[40%] bg-white rounded-full p-2 2xl:p-5 inner-counterclockwise"
              />
              <img
                src={inner}
                alt="inner circle"
                className="inner-clockwise absolute top-[21%] left-[20%] w-[60%]"
              />

              {/* Inner clockwise and counterclockwise items */}
              <div className="absolute right-[25.5%] top-[22.5%] bg-white p-2 2xl:p-2.5 inner-counterclockwise">
                <img src={diagram} alt="diagram" />
              </div>
              <div className="absolute left-[25.5%] bottom-[22.5%] bg-white p-2 2xl:p-2.5 inner-counterclockwise">
                <img src={goal} alt="goal" />
              </div>

              {/* Outer circle remains with rotate animation */}
              <img src={outer} alt="Outer circle" className="relative outer-clockwise" />

              {/* Idea and privacy elements */}
              <div className="absolute left-[9.5%] top-[9.5%] bg-white p-2 2xl:p-2.5 inner-counterclockwise">
                <img src={idea} alt="idea" />
              </div>
              <div className="absolute right-[9.5%] bottom-[9.5%] bg-white p-2 2xl:p-2.5 inner-counterclockwise">
                <img src={privacy} alt="privacy" />
              </div>
            </div>
          </div>
        </div>
      )}
    </>
  );
};
export default Login;
