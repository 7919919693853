import React, { useEffect, useState } from 'react'
import downArrow from '../../assets/icons/down-arrow1.png';
import moment from 'moment';
import { DateRangePicker } from 'materialui-daterange-picker';
import useToast from '../Hooks/Toast';
import calender from '../../assets/icons/weekend (1).png';

const DateRangePickerModal = ({ handleRangeChange, pickDate, setPickDate, defaultDate }) => {
    const maxDate = moment().toDate();
    return (
        <DateRangePicker
            open={pickDate}
            toggle={() => setPickDate(false)}
            closeOnClickOutside={true}
            initialDateRange={defaultDate}
            onChange={(range) => {
                handleRangeChange(range);
                setPickDate(false);
            }}
            maxDate={maxDate}
        />
    );
}

const Datepicker = ({ selectedDateRange, setselectedDateRange }) => {
    const [pickDate, setPickDate] = useState(false);
    const [showDatePicker, setShowDatePicker] = useState(false);

    const notify = useToast();

    useEffect(() => {
        const startOfLast7Days = moment().subtract(7, 'days').format('YYYY-MM-DD');
        const endOfLast7Days = moment().format('YYYY-MM-DD');
        setselectedDateRange({
            startDate: startOfLast7Days,
            endDate: endOfLast7Days,
        });
    }, [setselectedDateRange]);

    const handleRangeChange = (range) => {
        const startDate = moment(range.startDate);
        const endDate = moment(range.endDate);

        if (endDate.diff(startDate, 'months', true) <= 1) {
            setselectedDateRange({
                startDate: startDate.format('YYYY-MM-DD'),
                endDate: endDate.format('YYYY-MM-DD'),
            });
        } else {
            notify('The difference between the start and end date should not be more than one month.');
        }
    };

    const formatDateRange = () => {
        if (selectedDateRange.startDate && selectedDateRange.endDate) {
            return `${selectedDateRange.startDate}  -  ${selectedDateRange.endDate}`;
        }
        return 'Select date';
    };

    const handleDatePickerToggle = () => {
        setShowDatePicker(true);
        setPickDate(!pickDate);
    };

    return (
        <div className=''>
            <div className='relative top-24'>
                <div className="flex items-center hover:cursor-pointer">
                    <div
                        onClick={handleDatePickerToggle}
                        className="border border-[#1c4546] rounded-lg pl-4 text-left text-[#1c4546]"
                        style={{
                            height: '57px',
                            width: '270px',
                            backgroundColor: 'white',
                            borderRadius: '10px',
                            display: 'flex',
                            alignItems: 'center',
                            cursor: 'pointer',
                        }}
                    >
                        <img src={calender} alt='calender' className='mr-3' />
                        {formatDateRange()}
                    </div>
                    <img
                        src={downArrow}
                        alt='down-arrow'
                        onClick={handleDatePickerToggle}
                        className='absolute right-4 top-1/2 h-[14.5px] transform -translate-y-1/2'
                    />
                </div>
            </div>

            {showDatePicker && pickDate && (
                <div className="absolute w-[740px] z-10 right-[50px] top-[160px]">
                    <DateRangePickerModal
                        handleRangeChange={handleRangeChange}
                        pickDate={pickDate}
                        setPickDate={setPickDate}
                        defaultDate={{
                            startDate: selectedDateRange.startDate,
                            endDate: selectedDateRange.endDate,
                        }}
                    />
                </div>
            )}
        </div>
    );
}

export default Datepicker


// const handleRangeChange = (range) => {
//     // setStartDate(range.startDate);
//     // setEndDate(range.endDate);
//     setselectedDateRange({
//         startDate: moment(range.startDate).format('YYYY-MM-DD'),
//         endDate: moment(range.endDate).format('YYYY-MM-DD')
//     })
// };