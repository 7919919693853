//   https://monitoring-system-backend-234023401939.us-central1.run.app/

import axios from 'axios';
import useToast from '../Toast';
import { cookieGetter } from '../../utils/HelperFunction';
import { useNavigate } from 'react-router-dom';
import { NEW_BACKENDBASEURL } from '../../utils/constants';


const useAccount = () => {
    const appIdToken = cookieGetter("appIdToken");
    const navigate = useNavigate();
    const notify = useToast();


    const apiCall = async (bodyData) => {
        const url = `${NEW_BACKENDBASEURL}/users/setup-account`;
        const method = 'POST'

        try {
            const response = await axios({
                method: method,
                url: url,
                headers: {
                    "Content-Type": "application/json",
                    Authorization: `Bearer ${appIdToken}`,
                },
                data: bodyData,
            });

            if (response.status !== 200) {
                notify(`There is some issue in creating account please try again`, 'error');
            } else {
                // notify(`${response?.data?.message === 'Account activated successfully. You can now log in.'}Account activated successfully. You can now log in.`, 'success');
                // notify(`${response?.data?.detail}Account is already setup. Use login with password.`, 'warning');

                if (response?.data?.message) {
                    notify(response?.data?.message, 'success');
                } else if (response?.data?.detail) {
                    notify(response?.data?.detail, 'warning');
                }

                setTimeout(() => {
                    navigate("/login");
                }, 5000);
            }
        } catch (error) {
            notify(`There is some issue in creating account please try again`, 'error');
            console.error('Error:', error);
        }
    }
    return { apiCall };
};

export default useAccount;
// Account is already setup. Use login with password.
