import { useState } from "react";
import { cookieGetter } from "../../utils/HelperFunction";
import axios from "axios";
import useToast from "../Toast";
import { NODEBACKENDNEW } from "../../utils/constants";

const UseCampaign = () => {
    const appIdToken = cookieGetter("appIdToken");
    const [loading, setLoading] = useState(false);
    const [campaignData, setCampaignData] = useState([]);
    const notify = useToast();

    const campaignApiCall = async (campaignObject) => {
        setLoading(true);
        try {
            const response = await axios({
                method: "post",
                url: `${NODEBACKENDNEW}/dashboard/campaign-table`,
                headers: {
                    "Content-Type": "application/json",
                    Authorization: `Bearer ${appIdToken}`,
                },
                data: campaignObject,
            });

            if (response.status !== 200) {
                notify("There is some issue please try again", "error");
                setCampaignData([]);
                setLoading(false);
            } else {
                setLoading(false);
                setCampaignData(Object.entries(response?.data));
            }
        } catch (error) {
            notify("There is some issue in fetching data please try again", "error");
            console.log("There is some issue please try again : ", error);
            setLoading(false);

        } finally {
            setLoading(false);
        }
    };
    return {
        campaignApiCall,
        loading,
        setLoading,
        campaignData,
    };
};

export default UseCampaign;