import React, { useEffect, useState } from "react";
import "../../assets/style/common.scss";
import LoginIcon from "../../assets/icons/google.svg";
import ShowPassword from "../../assets/icons/showPassword.svg";
import HidePassword from "../../assets/icons/hidePassword.svg";
import { GoogleOAuthProvider } from "@react-oauth/google";
import { GoogleLogin } from "@react-oauth/google";
import LoginController from "./login-controller";
import { ToastContainer } from "react-toastify";
import inner from '../../assets/images/Inner Circle.png'
import idea from '../../assets/icons/idea (5).png'
import privacy from '../../assets/icons/privacy (2) (1).png'
import diagram from '../../assets/icons/diagram (4).png'
import goal from '../../assets/icons/goal (2).png'
import outer from '../../assets/images/Outer Circle.png'
import dv360 from '../../assets/images/dv360_logo.png'
import { useSearchParams } from "react-router-dom"
import google from '../../assets/icons/google.svg'
import useAccount from "../Hooks/accountSetup/useAccount";

const AccountSetup = () => {
    const [urlQuery, setUrlQuery] = useState('');
    const [searchParams] = useSearchParams();
    const [email, setEmail] = useState('');
    const [errors, setErrors] = useState({});
    const [isSubmitting, setIsSubmitting] = useState(false);
    const [showPassword, setShowPassword] = useState(false);

    const { responseGoogle, loading } = LoginController();
    const { apiCall } = useAccount();

    const [values, setValues] = useState({
        email: "",
        createPassword: "",
        confirmPassword: "",
    });

    useEffect(() => {
        const searchedParam = searchParams.get('method');
        const emailParam = searchParams.get('email');
        setUrlQuery(searchedParam);
        setEmail(emailParam || '');
        setValues((prevValues) => ({ ...prevValues, email: emailParam || '' }));
    }, [searchParams])

    const handleClickShowPassword = () => {
        setShowPassword(!showPassword);
    };

    const handleMouseDownPassword = (event) => {
        event.preventDefault();
    };

    const handlePasswordChange = (prop) => (event) => {
        setValues({ ...values, [prop]: event.target.value });
    };

    const validatePassword = (password) => {
        const passwordRegex = /^(?=.*[0-9])(?=.*[!@#$%^&*])[a-zA-Z0-9!@#$%^&*]{6,128}$/;
        return passwordRegex.test(password) && !password.includes(" ");
    };

    const handleSubmit = (e, method) => {
        e.preventDefault();

        const finalObj = {
            email: values.email,
            method: method,
            ...(method === 'create' && { password: values.createPassword }),
            // password: values.createPassword,
            // method: urlQuery || 'google',
        }

        let formErrors = {};

        if (values.createPassword !== values.confirmPassword) {
            formErrors.confirmPassword = "Passwords do not match";
        }

        if (!validatePassword(values.createPassword)) {
            formErrors.createPassword = [
                "Password must:",
                "• Minimum 6 and Maximum 128 characters long",
                "• Contain at least one number",
                "• Contain at least one special character (!@#$%^&*)",
                "• Not contain spaces"
            ];
        }

        setErrors(formErrors);

        setIsSubmitting(false);

        if (Object.keys(formErrors).length === 0 || method === 'google') {
            apiCall(finalObj);
            //   .then(() => {
            //     // setLoading(false);
            //     window.location.reload(); // Reload the whole page
            //   });
        }

    };


    return (
        <>
            {loading ? (
                <div className="none">
                    <div className="loading flex align-center justify-center">
                        <div className="loading-Overlay"> </div>
                    </div>
                </div>
            ) : (
                <div className="container-login">
                    <div className="login">
                        <div className="login-box max-xl:w-[200px]">
                            <div className="text-2xl font-bold text-center pb-2 xl:pb-7 2xl:pb-12">Account Setup</div>
                            {urlQuery === 'create' ?
                                <div>
                                    <form>
                                        <input
                                            className="input-1"
                                            type="text"
                                            placeholder="Email"
                                            value={email}
                                            readOnly={!!email}
                                        />

                                        <div className="password mt-7">
                                            <input
                                                className="input-1"
                                                placeholder="Create Password"
                                                type={showPassword ? "text" : "password"}
                                                onChange={handlePasswordChange("createPassword")}
                                                value={values?.createPassword}
                                            />
                                            <span
                                                onClick={handleClickShowPassword}
                                                onMouseDown={handleMouseDownPassword}
                                            >
                                                {showPassword ? (
                                                    <img src={ShowPassword} alt="Show" />
                                                ) : (
                                                    <img src={HidePassword} alt="Hide" />
                                                )}
                                            </span>
                                        </div>
                                        <div className="password">
                                            <input
                                                className="input-1 mt-7"
                                                placeholder="Confirm Password"
                                                type={showPassword ? "text" : "password"}
                                                onChange={handlePasswordChange("confirmPassword")}
                                                value={values?.confirmPassword}
                                            />
                                        </div>
                                        {errors?.createPassword && (
                                            <div className="text-red-500 mt-1">
                                                {errors?.createPassword?.map((error, index) => (
                                                    <span key={index} className="block text-xs xl:text-sm">{error}</span>
                                                ))}
                                            </div>
                                        )}
                                        {errors?.confirmPassword && (
                                            <div className="text-red-500 mt-1">
                                                <span className="block text-xs xl:text-sm">{errors?.confirmPassword}</span>
                                            </div>
                                        )}
                                        <button
                                            type="submit"
                                            // onClick={handleSubmit('create')}
                                            onClick={(e) => handleSubmit(e, 'create')}
                                            className="button bg-primaryColor mt-7 py-5"
                                            disabled={isSubmitting}
                                        // disabled={!validatePassword(values?.createPassword) || isSubmitting || values?.createPassword !== values?.confirmPassword}
                                        >
                                            Sign Up
                                        </button>
                                        {/* <p className="already-user">
                                        Already a member? <a href="/login">Log in</a>
                                    </p> */}
                                    </form>
                                    <div className="pt-3">
                                        Want to create account with google? <a href={`/account-setup?method=google&email=${email}`}>click here</a>
                                    </div>
                                </div>

                                :

                                <div>
                                    <button onClick={(e) => handleSubmit(e, 'google')} className="mx-auto flex items-center bg-blue-500 text-white font-medium py-2 px-4 rounded-lg shadow-md hover:bg-blue-600">
                                        <div className="bg-white p-2 rounded-l-lg">
                                            <img
                                                src={google}
                                                alt="Google logo"
                                                className="w-6 h-6"
                                            />
                                        </div>
                                        <span className="ml-2">Sign in with Google</span>
                                    </button>

                                    <div className="pt-3">
                                        Want to create account using email and password? <a href={`/account-setup?method=create&email=${email}`}>click here</a>
                                    </div>
                                </div>
                            }
                            <ToastContainer />
                        </div>
                    </div>

                    <div className="outer-ring absolute right-[10%] 2xl:right-[9%] top-[5%] lg:top-[13%] 2xl:top-[17%] max-2xl:w-[35%]">
                        <div className="inner-ring relative overflow-hidden">
                            <img
                                src={dv360}
                                alt="dv360"
                                className="absolute top-[40%] left-[40%] bg-white rounded-full p-2 2xl:p-5 inner-counterclockwise"
                            />
                            <img
                                src={inner}
                                alt="inner circle"
                                className="inner-clockwise absolute top-[21%] left-[20%] w-[60%]"
                            />

                            {/* Inner clockwise and counterclockwise items */}
                            <div className="absolute right-[25.5%] top-[22.5%] bg-white p-2 2xl:p-2.5 inner-counterclockwise">
                                <img src={diagram} alt="diagram" />
                            </div>
                            <div className="absolute left-[25.5%] bottom-[22.5%] bg-white p-2 2xl:p-2.5 inner-counterclockwise">
                                <img src={goal} alt="goal" />
                            </div>

                            {/* Outer circle remains with rotate animation */}
                            <img src={outer} alt="Outer circle" className="relative outer-clockwise" />

                            {/* Idea and privacy elements */}
                            <div className="absolute left-[9.5%] top-[9.5%] bg-white p-2 2xl:p-2.5 inner-counterclockwise">
                                <img src={idea} alt="idea" />
                            </div>
                            <div className="absolute right-[9.5%] bottom-[9.5%] bg-white p-2 2xl:p-2.5 inner-counterclockwise">
                                <img src={privacy} alt="privacy" />
                            </div>
                        </div>
                    </div>
                </div>
            )}
        </>
    );
};
export default AccountSetup;



// <GoogleOAuthProvider clientId={process.env.REACT_APP_GOOGLE_API_TOKEN}>
//     <GoogleLogin
//         render={(renderProps) => (
//             <button
//                 className="log-in-btn button flex justify-center rounded-[10px]"
//                 onClick={renderProps.onClick}
//                 disabled={renderProps.disabled}
//             >
//                 <img src={LoginIcon} alt="Log in" />
//                 Log In with Google
//             </button>
//         )}
//         onSuccess={responseGoogle}
//         onFailure={responseGoogle}
//         cookiePolicy="single_host_origin"
//     />
// </GoogleOAuthProvider>
// <div>
//     <input className="input-1" type="text" placeholder="Email" />
//     <div className="password">
//         <input
//             className="input-1"
//             placeholder="Password"
//             type={values.showPassword ? "text" : "password"}
//             onChange={handlePasswordChange("password")}
//             value={values.password}
//         />
//         <span
//             onClick={handleClickShowPassword}
//             onMouseDown={handleMouseDownPassword}
//         >
//             {values.showPassword ? (
//                 <img src={ShowPassword} alt="Log in" />
//             ) : (
//                 <img src={HidePassword} alt="Log in" />
//             )}
//         </span>
//     </div>

//     <button className="button bg-primaryColor mt-45 py-5">Sign Up</button>

//     <p className="already-user">
//         Already a member?
//         <a href="/login">Log in</a>
//     </p>
// </div>