import { useCallback, useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import axios from "axios";
import useToast from "../Hooks/Toast"
import { cookieGetter } from '../utils/HelperFunction'
import { NEW_BACKENDBASEURL } from "../utils/constants";

const CategoryController = (selected) => {
  const navigate = useNavigate();
  const appIdToken = cookieGetter("appIdToken");
  const [currMonitoringConfig, setCurrMonitoringConfig] = useState();
  const [loadingList, setLoadingList] = useState(false);
  const notify = useToast();
  const baseURL =
    `${NEW_BACKENDBASEURL}/monitoring-configs`;
  const [entityTypeData, setEntityTypeData] = useState();
  const [entityID, setEntityID] = useState([]);
  const getFetchEntitiesUsingAPI = useCallback(
    async (selected) => {
      setLoadingList(true);
      try {
        const baseUrl = `${NEW_BACKENDBASEURL}`;
        const response = await axios.get(
          `${NEW_BACKENDBASEURL}/dv360/entities`,
          {
            baseUrl,
            method: "GET",
            params: { entityType: selected.toUpperCase() },
            headers: { Authorization: `Bearer ${appIdToken}` },
          }
        );

        setEntityTypeData(response.data);
        setEntityID(response.data.id);
        setLoadingList(false);
        return response.data;
      } catch (error) {
        notify(`Error fetching entities`, 'error');
        console.error("Error fetching entities:", error);
        throw error;
      }
    },
    [appIdToken]
  );

  const saveMonitoringConfig = async (finalObject) => {
    setLoadingList(true);
    try {
      const response = await fetch(
        finalObject?.id
          ? `${NEW_BACKENDBASEURL}/monitoring-configs/${finalObject?.id}`
          : baseURL,
        {
          method: finalObject.id ? "PUT" : "POST",
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${appIdToken}`,
          },
          body: JSON.stringify(finalObject),
        }
      );

      if (!response.ok) {
        notify(`There is some issue please try again`, 'error');
        throw new Error(`HTTP error! Status: ${response.status}`);
      }
      else {
        notify(`The Alert is been Configured`, 'success');
        navigate("/home");
      }

      setLoadingList(false);
    } catch (error) {
      setLoadingList(false);
      notify(`There is some issue please try again`, 'error');
      console.error("Error:", error);
    }
  };



  useEffect(() => {
    if (currMonitoringConfig?.length) {
      saveMonitoringConfig();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [currMonitoringConfig]);
  useEffect(() => {
    getFetchEntitiesUsingAPI(selected)
      .then((data) => {
        setEntityTypeData(data);
      })
      .catch((error) => {
        console.error("Error fetching data:", error);
      });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selected]);

  return {
    entityTypeData,
    entityID,
    saveMonitoringConfig,
    loadingList,
    setCurrMonitoringConfig,
  };
};

export default CategoryController;
