import { useState } from "react";
import { cookieGetter } from "../../utils/HelperFunction";
import axios from "axios";
import useToast from "../Toast";
import { NODEBACKENDNEW } from "../../utils/constants";

const UsePerformanceOverview = () => {
  const appIdToken = cookieGetter("appIdToken");
  const [loading, setLoading] = useState(false);
  const [performanceData, setPerformanceData] = useState(null);
  const notify = useToast();

  const performanceApiCall = async (performanceObject) => {
    try {
      const response = await axios({
        method: "post",
        url: `${NODEBACKENDNEW}/dashboard/performance`,
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${appIdToken}`,
        },
        data: performanceObject,
      });

      if (response.status !== 200) {
        notify("There is some issue please try again", "error");
        setPerformanceData({});
      } else {
        setPerformanceData(response?.data);
      }
    } catch (error) {
        notify("There is some issue in fetching data please try again", "error");
      console.log("There is some issue please try again : ", error);
    } finally {
      setLoading(false);
    }
  };
  return {
    performanceApiCall,
    loading,
    setLoading,
    performanceData,
  };
};

export default UsePerformanceOverview;
