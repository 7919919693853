import { FormControl, InputLabel, MenuItem, Select, InputAdornment, OutlinedInput } from "@mui/material";
import React, { useEffect, useState } from "react";
import AdvertiserDetails from "../Hooks/Dashboard/useAdvertiser";
import marketing from "../../assets/icons/marketing (6).png";

const AdvertiserList = ({ setAdvertiserId }) => {
    const {
        advertiser = [],
        getAdvertiser,
        loading,
    } = AdvertiserDetails();

    const [selectedAdvertiser, setSelectedAdvertiser] = useState("");

    const handleAdvertiser = (e) => {
        const advertiserName = e.target.value;
        setSelectedAdvertiser(advertiserName);

        const selectedAdv = advertiser.find((item) => item.name === advertiserName);
        setAdvertiserId(selectedAdv?.id);
    };

    useEffect(() => {
        getAdvertiser();
    }, []);

    useEffect(() => {
        if (advertiser.length > 0) {
            const defaultAdvertiser = advertiser[0];
            setSelectedAdvertiser(defaultAdvertiser.name);
            setAdvertiserId(defaultAdvertiser.id);
        }
    }, [advertiser]);

    return (
        <div className="lg:min-w-40 max-h-5">
            <FormControl fullWidth sx={{ borderColor: "red" }}>
                <InputLabel
                    id="advertiser-select-label"
                    sx={{
                        color: "#1c4546",
                        "&.Mui-focused": {
                            color: "red",
                        },
                        "&.MuiInputLabel-shrink": {
                            display: 'none'
                        },
                    }}
                >
                    Select Advertiser
                </InputLabel>
                <Select
                    labelId="advertiser-select-label"
                    id="advertiser-select"
                    value={loading ? "Loading..." : selectedAdvertiser}
                    onChange={handleAdvertiser}
                    // displayEmpty
                    disabled={loading}
                    input={
                        <OutlinedInput
                            startAdornment={
                                <InputAdornment position="start">
                                    <img
                                        src={marketing}
                                        alt="marketing"
                                        className="mr-3 pb-0.5"
                                    />
                                </InputAdornment>
                            }
                            sx={{
                                height: "55px",
                                width: "220px",
                                backgroundColor: "white",
                                color: "#1c4546",
                                borderRadius: "10px",
                                "& .MuiOutlinedInput-notchedOutline": {
                                    borderColor: "#1c4546",
                                },
                                "&:hover .MuiOutlinedInput-notchedOutline": {
                                    borderColor: "#1c4546",
                                },
                                "&.Mui-focused .MuiOutlinedInput-notchedOutline": {
                                    borderColor: "#1c4546",
                                },
                                "& .MuiSvgIcon-root": { color: "#1c4546" }
                            }}
                        />
                    }
                >
                    {loading ? (
                        <MenuItem value="Loading..." disabled>
                            Loading...
                        </MenuItem>
                    ) : (
                        advertiser?.map((item) => (
                            <MenuItem key={item?.id} value={item?.name}>
                                {item?.name}
                            </MenuItem>
                        ))
                    )}
                </Select>
            </FormControl>
        </div>
    );
};

export default AdvertiserList;
